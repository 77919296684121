@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .content {
    text-align: center;
  }

  .successText {
    margin-top: md;
    text-align: center;
  }

  .messagesCta {
    margin-top: md;
  }
}
