@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    padding: sm md md;
  }

  .header {
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: xs;
  }
}
