@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .submitButton {
    margin: md 0;
  }

  .timeWrapper {
    display: grid;
    grid-template-columns: 18px 1fr;
    align-items: center;
    gap: sm;
  }

  .shippingText {
    text-transform: lowercase;
  }
}